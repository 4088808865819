import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';

import { resolveSlice } from 'utils/slice-resolver.js';

import Hero from 'components/Hero';
import useSiteMetadata from 'hooks/useSiteMetadata';

import css from './application.module.scss';

const Application = ({ data: { prismic }, location }) => {
  const { siteUrl } = useSiteMetadata();

  const content = prismic.allApplications.edges[0];
  if (!content) return null;

  const application = content.node;
  const image = application.hero_imageSharp;
  const slices = application.body || [];
  return (
    <>
      <Helmet>
        <title>{`SwissDeCode - ${RichText.asText(application.name)}`}</title>
        <meta description={RichText.asText(application.description)} />
        <meta property="og:title" content={RichText.asText(application.name)} />
        <meta
          property="og:description"
          content={RichText.asText(application.description)}
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:image" content={application.hero_image.url} />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        logo={application.logo.url}
        logoClassName={css.heroLogo}
        title={application.name}
        description={application.description}
      />
      {slices.map(resolveSlice)}
    </>
  );
};

export const pageQuery = graphql`
  query ApplicatonBySlug($uid: String) {
    prismic {
      allApplications(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            body {
              ... on PRISMIC_ApplicationBodyContent_section {
                fields {
                  main
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 420) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  content_text
                }
                type
              }
            }
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            logo
            name
            description
          }
        }
      }
    }
  }
`;

Application.query = pageQuery;
export default Application;
